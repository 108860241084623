export enum OrderStatus {
  OPEN = 'OPEN', // créé

  PRE_PROCESSING = 'PRE_PROCESSING', // en attente de traitement logilab
  // AWAITING_SUPPLY = 'AWAITING_SUPPLY', // en attente d'appro logilab
  IN_PROCESSING = 'IN_PROCESSING', // en préparation logilab
  AWAITING_SHIPMENT = 'AWAITING_SHIPMENT', // en attente d'expédition logilab
  SENT = 'SENT', // Expedier logilab

  UNKNOWN = 'UNKNOWN', // Inconnu
}
